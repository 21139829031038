/* eslint-disable */
import {
  AddEventId,
  GetAgreements,
  ValidateAddress,
  GetCustomerAlerts,
  GetCustomerAlertsById,
  GetCustomerCoverage,
  ManageDrawerAudit,
  StoreSearch,
  GetCustomerAddress,
  UpdateAddressInfo,
  GetCustomerPhone,
  getIntellicheckStatus,
} from "../api/user";
import {
  DrawerAudit,
  GetCustomer,
  GetCustomerInfoOptions,
  GetEmployementOptions,
  GetLegalStatus,
  GetMenuAndStoreBasedOnProfile,
  GetStoreProfile,
  UpdateCustomerInfo,
  manageStoreActionItem
} from "../api/user";

import crypto, { createDecipheriv } from "crypto";

import {
  AGREEMENT_DETAILS,
  BASIC_INFORMATION_TEMPLATE,
  CLUB_INFO_TEMPLATE,
  COMMUNICATION_PREFERENCE_TEMPLATE,
  CONFIGURATION_INFO_TEMPLATE,
  CONTACT_INFORMATION_TEMPLATE,
  DEFAULT_DROPDOWN_OPTION,
  EMPLOYER_INFO_TEMPLATE,
  EMPTY_STRING,
  FAX_SHEET_INFO_TEMPLATE,
  getAgreementByIdTemplate,
  IDENTITY_DETAILS,
  LEGAL_HOLD_INFO_TEMPLATE,
  LOGGED_IN_STORE,
  REFERENCE_INFO_TEMPLATE,
  RESIDENCE_INFO_TEMPLATE,
  SKIP_HARD_TEMPLATE,
  STRING_NO,
  STRING_YES,
  STRING_ZERO,
  TAX_EXEMPT_TEMPLATE,
  VEHICLE_INFORMATION_TEMPLATE,
  VERIFICATION_EXPIRY_DAYS,
} from "../constants/constants";

import {
  AddressDetails,
  AddressDetailsBinder,
  AddressInfo,
  AgrHistoryEventId,
  ApiResponse,
  ConfigurationsRequest,
  CustomerApproval,
  DEHistoryRequest,
  DropdownOptions,
  EmployerDetails,
  EventIdRequest,
  GetAgreementByIdResponse,
  IdentityDetailsInfo,
  SuggestedAddress,
  VehicleInfomation,
  UpdateCustomerPayload,
  ResidenceInfo,
} from "../interface/globalInterface";

import {
  AgreementDetails,
  AlertInformation,
  ClubInformation,
  CommunicationInformation,
  Configurations,
  ContactInformations,
  CustomerBasicInformation,
  FaxSheetInformation,
  GetCustomerResponse,
  LegalHoldInfomation,
  ReferenceInformation,
  ResidenceInformation,
  SkipHardStatus,
  StoreSearchResponse,
  TaxExemptInformation,
} from "../interface/updateCustomerInterface";

import {
  buildPaySchduleDD,
  formDropdownOptions,
  sortByLabel,
  validateApiResponse,
} from "../utils/utils";

import {
  buildAddressInfo,
  buildBasicInformation,
  buildCommunicationPreference,
  buildContactInformation,
  buildEmployerInformation,
  buildIdentityDetails,
  buildInformationPageDropdowns,
  buildReferenceDetails,
  buildResidenceInformation,
  buildSkipStolenHardInfo,
  buildTaxExemptInformation,
  buildVehicleinformation,
} from "./mapperFn/updateCustomerMapperFn";

import {
  buildAddressDetailsBinder,
  buildAdressInformations,
  buildInActiveAddressDetails,
} from "../components/sharedComponents/AddressInformation/AddressInfoUtils";
import { getCustomerVerificationDocuments } from "../components/sharedComponents/Utils";

let verificationExipryDays: string = VERIFICATION_EXPIRY_DAYS;
/**
 * Purpose: To fetch the coworker role who logged in the application
 */
const getCoworkerRole = async (containerData: any): Promise<string> => {
  try {
    let role: string = EMPTY_STRING;
    if (containerData) {
      role = containerData.GetRole();
    } else {
      const menuAndStoresResponse: ApiResponse =
        await GetMenuAndStoreBasedOnProfile({
          coworkerId: "",
          storesRequired: false,
          menuRequired: false,
        });
      if (
        validateApiResponse(menuAndStoresResponse) &&
        menuAndStoresResponse.data.coworkerProfile?.role
      ) {
        role = menuAndStoresResponse.data.coworkerProfile.role;
      }
    }
    return role;
  } catch (error: any) {
    console.log("Error in getCoworkerRole: ", error?.message);
    return EMPTY_STRING;
  }
};

/**
 * @param customerId - The customer id of whose legal hold status need to be found
 * Purpose: To find whether a customer is legally hold or not
 */
const getCustomerLegalHoldStatus = async (
  customerId: string
): Promise<LegalHoldInfomation> => {
  try {
    const legalHoldResponse: ApiResponse = await GetLegalStatus(customerId);
    const legalHoldInfo: LegalHoldInfomation = { ...LEGAL_HOLD_INFO_TEMPLATE };
    if (
      validateApiResponse(legalHoldResponse) &&
      legalHoldResponse.data.legalHoldStatusRes?.length &&
      ((legalHoldResponse.data.legalHoldStatusRes[0]?.legalHoldStatus &&
        legalHoldResponse.data.legalHoldStatusRes[0].legalHoldStatus ==
        "true") ||
        legalHoldResponse.data.legalHoldStatusRes[0].legalHoldStatus === true)
    ) {
      legalHoldInfo.legalHoldStatus = true;

      if (legalHoldResponse.data.legalHoldStatusRes[0].refCode) {
        legalHoldInfo.refCode =
          legalHoldResponse.data.legalHoldStatusRes[0].refCode;
      }
    }
    return legalHoldInfo;
  } catch (error: any) {
    console.log("Error in getCustomerLegalHoldStatus: ", error?.message);
    return { ...LEGAL_HOLD_INFO_TEMPLATE };
  }
};

const getStoreConfigurations = async (): Promise<Configurations> => {
  let configurations: Configurations = { ...CONFIGURATION_INFO_TEMPLATE };
  try {
    const configurationKeys: string[] = [
      "Verification_Expiry_Days",
      "Reinstatement",
      "AgreementTransfer",
      "CustomerVerificationOptimization",
      "Intellicheck",
      "EditRiskAssessmentRoles",
      "DocumentUploadEnable",
      "EnableCustomerVerificationPhase-II",
      "CustomerSourceVerificationLevels",
      "RequiredDocumentsVerificationLevels"
    ];

    const payload: ConfigurationsRequest = {
      storeNumbers: [LOGGED_IN_STORE],
      paramKeyNames: configurationKeys,
    };
    const configurationResponse: ApiResponse = await GetStoreProfile(payload);

    if (
      validateApiResponse(configurationResponse) &&
      configurationResponse.data.storeProfileResponse?.configDetails?.length &&
      configurationResponse.data.storeProfileResponse.configDetails[0]
        ?.configDetails
    ) {
      const configData: any =
        configurationResponse.data.storeProfileResponse.configDetails[0]
          .configDetails;

      configData?.forEach((configDetails: any) => {
        if (configDetails?.paramKeyName) {
          if (configDetails.paramKeyName === "Verification_Expiry_Days") {
            configurations.verificationDays = configDetails?.paramValue;
            verificationExipryDays = configDetails?.paramValue;
          } else if (
            configDetails.paramKeyName === "AgreementTransfer" &&
            configDetails?.paramValue == "1"
          ) {
            configurations.agreementTransferApplicable = true;
          } else if (
            configDetails.paramKeyName === "CustomerVerificationOptimization" &&
            configDetails?.paramValue == "1"
          ) {
            configurations.customerVerificationOptimization = true;
          } else if (
            configDetails.paramKeyName === "Reinstatement" &&
            configDetails?.paramValue == "1"
          ) {
            configurations.reinstatementAllowed = true;
          } else if (
            configDetails.paramKeyName === "Intellicheck"
          ) {
            configurations.intellicheck = configDetails?.paramValue;
          } else if (configDetails.paramKeyName === "EditRiskAssessmentRoles") {
            configurations.editRiskAssessmentRoles = configDetails?.paramValue?.split("~") ?? [];
          } else if (configDetails.paramKeyName === "DocumentUploadEnable" && configDetails?.paramValue == "1") {
            configurations.DocumentUploadEnable = true;
          } else if (configDetails.paramKeyName === "EnableCustomerVerificationPhase-II" && configDetails?.paramValue == "1") {
            configurations.EnableCustomerVerificationPhaseTwo = true;
          } else if (configDetails.paramKeyName === "CustomerSourceVerificationLevels") {
            configurations.CustomerSourceVerificationLevels = configDetails?.paramValue;
          } else if (configDetails.paramKeyName === "RequiredDocumentsVerificationLevels") {
            configurations.RequiredDocumentsVerificationLevels = configDetails?.paramValue;
          }
        }
      });
    }

    return configurations;
  } catch (error: any) {
    console.log("Error in getStoreConfigurations", error?.message);
  } finally {
    return configurations;
  }
};

const getCustomerInformation = async (
  customerId: string,
  featureFlagDetails: boolean,
  setVerificationDocuments: any,
  setIntellicheckDetails: any
): Promise<any> => {
  try {
    const customerInfoResponse: ApiResponse = await GetCustomer(
      customerId,
      LOGGED_IN_STORE
    );

    let basicInformation: CustomerBasicInformation = {
      ...BASIC_INFORMATION_TEMPLATE,
    };
    let taxExemptInformation: TaxExemptInformation = { ...TAX_EXEMPT_TEMPLATE };
    let communicationInformation: CommunicationInformation = {
      ...COMMUNICATION_PREFERENCE_TEMPLATE,
    };
    let residenceInformation: ResidenceInfo = {
      ...RESIDENCE_INFO_TEMPLATE,
    };
    let skipHardInformation: SkipHardStatus = { ...SKIP_HARD_TEMPLATE };
    let contactInformation: ContactInformations[] = [
      { ...CONTACT_INFORMATION_TEMPLATE },
    ];
    let vehicleInformation: VehicleInfomation[] = [
      { ...VEHICLE_INFORMATION_TEMPLATE },
    ];
    let referenceInformation: ReferenceInformation[] = [
      { ...REFERENCE_INFO_TEMPLATE },
    ];
    let employmentDetails: EmployerDetails[] = [{ ...EMPLOYER_INFO_TEMPLATE }];
    let identityDetails: IdentityDetailsInfo = IDENTITY_DETAILS;
    if (validateApiResponse(customerInfoResponse)) {
      //Processing the customer information
      console.log('Customer approval Response', customerInfoResponse?.data?.GetApproval?.value?.customerApprovalDetails?.decisionId);
      if (customerInfoResponse?.data?.GetApproval?.value && customerInfoResponse?.data?.GetApproval?.value?.customerApprovalDetails?.decisionId) {
        getCustomerVerificationDocuments(customerInfoResponse?.data?.GetApproval?.value?.customerApprovalDetails?.decisionId, setVerificationDocuments);
        getIntellicheckDetails(customerInfoResponse?.data?.GetApproval?.value?.customerApprovalDetails?.decisionId, setIntellicheckDetails)
      }
      if (
        customerInfoResponse?.data.GetCustomer &&
        customerInfoResponse.data.GetCustomer.value
      ) {
        const customerInformation: GetCustomerResponse =
          customerInfoResponse.data.GetCustomer.value;
        basicInformation = await buildBasicInformation(
          customerInformation,
          featureFlagDetails
        );
        communicationInformation = buildCommunicationPreference(
          customerInformation,
          featureFlagDetails
        );
        featureFlagDetails
          ? (identityDetails = buildIdentityDetails(
            basicInformation,
            customerInfoResponse?.data?.GetApproval?.value
              ?.customerApprovalDetails
          ))
          : null;
        skipHardInformation = buildSkipStolenHardInfo(customerInformation);
        residenceInformation = buildResidenceInformation(
          customerInformation,
          customerInfoResponse.data.GetApproval
        );
        if (customerInformation?.vehicles?.length) {
          vehicleInformation = buildVehicleinformation(
            customerInformation.vehicles
          );
        }

        if (customerInformation.phones?.length) {
          contactInformation = buildContactInformation(
            customerInformation.phones,
            featureFlagDetails
          );
        }

        if (customerInformation.taxExempts?.length) {
          taxExemptInformation = buildTaxExemptInformation(
            customerInformation.taxExempts[0]
          );
        }

        // if (customerInformation?.employerReferences?.length) {
        employmentDetails = buildEmployerInformation(
          customerInformation?.employerReferences,
          featureFlagDetails,
          customerInfoResponse?.data?.GetApproval?.value
            ?.customerApprovalDetails
        );
        // }
        // if (customerInformation.personalReferences?.length) {
        referenceInformation = buildReferenceDetails(
          customerInformation.personalReferences,
          verificationExipryDays,
          customerInfoResponse?.data?.GetApproval?.value?.customerApprovalDetails
        );
        // }
      }
    }
    return [
      basicInformation,
      skipHardInformation,
      taxExemptInformation,
      communicationInformation,
      residenceInformation,
      vehicleInformation,
      contactInformation,
      referenceInformation,
      employmentDetails,
      customerInfoResponse,
      identityDetails
    ];
  } catch (error: any) {
    console.log("Error in getCustomerInformation: ", error?.message);
  }
};

const getDropdownData = async (): Promise<Array<DropdownOptions[]>> => {
  let relationshipOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let govtIDTypeOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let legalHoldOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let saluationOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let suffixOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let bestTimeToCallOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let phoneTypeOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let preferredContactOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let taxExemptStatusOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let stateOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let routeOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let payDayFrequency: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let weeklyDropdownOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let monthlyDropdownOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  let semiMonthlyDropdownOptions: DropdownOptions[] = [
    ...DEFAULT_DROPDOWN_OPTION,
  ];
  let incomeSourceOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION]
  let intellicheckIdOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION]

  try {
    const [
      dropdownResponse,
      weeklyDropdownResponse,
      monthlyDropdownResponse,
      semiMonthlyDropdownResponse,
    ]: ApiResponse[] = await Promise.all([
      GetCustomerInfoOptions(LOGGED_IN_STORE),
      GetEmployementOptions("1"),
      GetEmployementOptions("3"),
      GetEmployementOptions("5"),
    ]);

    if (validateApiResponse(dropdownResponse)) {
      const dropdownsData = dropdownResponse.data;
      [
        relationshipOptions,
        govtIDTypeOptions,
        legalHoldOptions,
        saluationOptions,
        suffixOptions,
        bestTimeToCallOptions,
        phoneTypeOptions,
        preferredContactOptions,
        taxExemptStatusOptions,
        stateOptions,
        routeOptions,
        incomeSourceOptions,
        intellicheckIdOptions
      ] = buildInformationPageDropdowns(dropdownsData);
    }

    if (validateApiResponse(weeklyDropdownResponse)) {
      const dropDownResponse: any = buildPaySchduleDD(weeklyDropdownResponse);
      payDayFrequency = dropDownResponse.paidDropDown;
      weeklyDropdownOptions = dropDownResponse.daysPaidDropDown;
    }

    if (validateApiResponse(monthlyDropdownResponse)) {
      monthlyDropdownOptions = formDropdownOptions(
        monthlyDropdownResponse.data?.dayspaidDTO?.dayPaid,
        "daysPaidDescEn",
        "refCode",
        false,
        true,
        "label"
      );
    }

    if (validateApiResponse(semiMonthlyDropdownResponse)) {
      semiMonthlyDropdownOptions = formDropdownOptions(
        semiMonthlyDropdownResponse.data.dayspaidDTO?.dayPaid,
        "daysPaidDescEn",
        "refCode",
        false,
        true,
        "label"
      );
    }
    return [
      govtIDTypeOptions,
      stateOptions,
      saluationOptions,
      suffixOptions,
      bestTimeToCallOptions,
      phoneTypeOptions,
      preferredContactOptions,
      routeOptions,
      legalHoldOptions,
      taxExemptStatusOptions,
      relationshipOptions,
      payDayFrequency,
      weeklyDropdownOptions,
      monthlyDropdownOptions,
      semiMonthlyDropdownOptions,
      incomeSourceOptions,
      intellicheckIdOptions
    ];
  } catch (error: any) {
    console.log("Error in getDropdownData: ", error?.message);
    const emptyDropdown: Array<DropdownOptions[]> = [];
    for (let i = 0; i <= 15; i++) {
      emptyDropdown.push([...DEFAULT_DROPDOWN_OPTION]);
    }
    return emptyDropdown;
  }
};

const linkCustomerInfoInWeb = async (
  webLinkPayload: UpdateCustomerPayload
): Promise<string> => {
  try {
    let oktaStatus: string = EMPTY_STRING;
    const webLinkResponse: ApiResponse = await UpdateCustomerInfo(
      webLinkPayload,
      webLinkPayload.customerId
    );

    if (
      validateApiResponse(webLinkResponse) &&
      webLinkResponse.data.UpdateCustomer?.value?.oktaStatus
    ) {
      oktaStatus = webLinkResponse.data.UpdateCustomer.value.oktaStatus;
    }
    return oktaStatus;
  } catch (error: any) {
    console.log("Error in linkCustomerInfoInWeb: ", error?.message);
    return EMPTY_STRING;
  }
};

export const getDEHistory = async (
  request: DEHistoryRequest
): Promise<CustomerApproval[]> => {
  try {
    let dEHistoryResponse: any = [];
    const payload: DEHistoryRequest = {
      globalCustomerId: request.globalCustomerId,
      limit: request.limit || "",
      offset: request.offset || "",
    };
    const customerDEHistory: ApiResponse = await ManageDrawerAudit(payload);
    if (validateApiResponse(customerDEHistory) && customerDEHistory.data) {
      dEHistoryResponse = customerDEHistory.data.customerApprovalHistory;
    }
    return dEHistoryResponse;
  } catch (e: any) {
    console.log("Error in getDEHistory: ", e?.message);
    return [];
  }
};

const buildEventIdResponse = (response: any) => {
  if (
    response &&
    response.storeProfileResponse &&
    response.storeProfileResponse.configDetails &&
    response.storeProfileResponse.configDetails.length > 0 &&
    response.storeProfileResponse.configDetails[0].configDetails &&
    response.storeProfileResponse.configDetails[0].configDetails.length > 0
  ) {
    return response.storeProfileResponse.configDetails[0].configDetails;
  } else {
    return [];
  }
};

const getFeatureFlagInfo = async (
  payload: EventIdRequest
): Promise<AgrHistoryEventId[]> => {
  try {
    let featureFlagResponse: any = [];
    const response: ApiResponse = await AddEventId(payload);
    if (validateApiResponse(response) && response.data) {
      const buildResponse = buildEventIdResponse(response.data);
      featureFlagResponse = buildResponse;
    }
    return featureFlagResponse;
  } catch (e: any) {
    console.log("Error in getFeatureFlagInfo: ", e?.message);
    return [];
  }
};

const getAgreementHistory = async (
  customerId: string,
  inactive: "Y" | "N"
): Promise<GetAgreementByIdResponse> => {
  let agreementResponse: GetAgreementByIdResponse = {
    ...getAgreementByIdTemplate,
  };
  try {
    const response: any = await GetAgreements(customerId, inactive);
    if (validateApiResponse(response) && response.data) {
      agreementResponse = response.data;
    }
    return agreementResponse;
  } catch (e: any) {
    console.log("Error in getAgreementHistory: ", e?.message);
    return agreementResponse;
  }
};

const getFaxSheetDetails = async () => {
  let storeDetails: FaxSheetInformation = { ...FAX_SHEET_INFO_TEMPLATE };
  try {
    const faxSheetDataResponse: ApiResponse = await StoreSearch(
      LOGGED_IN_STORE
    );
    if (
      validateApiResponse(faxSheetDataResponse) &&
      faxSheetDataResponse.data?.response?.length &&
      faxSheetDataResponse.data.response[0]
    ) {
      const storeSearchResponse: StoreSearchResponse =
        faxSheetDataResponse.data.response[0];
      storeDetails.storeName = storeSearchResponse.storeName || EMPTY_STRING;
      storeDetails.storeNumber =
        storeSearchResponse.storeNumber || EMPTY_STRING;
      storeDetails.stateName = storeSearchResponse.stateName || EMPTY_STRING;
      storeDetails.addressLine1 =
        storeSearchResponse.addressLine1 || EMPTY_STRING;
      storeDetails.fullZip = storeSearchResponse.fullZip || EMPTY_STRING;
      storeDetails.city = storeSearchResponse.city || EMPTY_STRING;
      storeDetails.workPhoneNumber =
        storeSearchResponse.workPhoneNumber || EMPTY_STRING;
    }
  } catch (error: any) {
    console.log("Error in getFaxSheetDetails: ", error?.message);
  } finally {
    return storeDetails;
  }
};

const validateTransferAgreements = (
  agreementsInCurrentStore: any[],
  template: AgreementDetails
): AgreementDetails => {
  const agreementDetail: AgreementDetails = { ...template };
  try {
    const switchoutAgreements: any[] = agreementsInCurrentStore.filter(
      (e: any) => e?.switchout == 1
    );
    const deliveryConfirmedAgreements: any[] = agreementsInCurrentStore.filter(
      (e: any) => e?.isDeliveryConfirmed == 0
    );
    const deliveryOpenAgreements: any[] = agreementsInCurrentStore.filter(
      (e: any) => e?.isDeliveryConfirmed == 1
    );

    if (switchoutAgreements.length) {
      agreementDetail.tranferAgreementInfo =
        "Customer has agreement(s) that are pending switch out";
    }
    if (
      deliveryConfirmedAgreements.length ||
      (deliveryConfirmedAgreements.length && switchoutAgreements.length)
    ) {
      agreementDetail.tranferAgreementInfo =
        "Customer has Out of Store Activities";
    }
    if (
      switchoutAgreements.length == 0 &&
      deliveryOpenAgreements.length == agreementsInCurrentStore.length
    ) {
      agreementDetail.transferAppilicable = true;
    }
  } catch (error: any) {
    console.log("Error in validateTransferAgreements: ", error?.message);
  } finally {
    return agreementDetail;
  }
};

const getAgreementDetails = async (
  customerId: string
): Promise<AgreementDetails> => {
  let agreemntInfo: AgreementDetails = { ...AGREEMENT_DETAILS };

  try {
    const agreementResponse: ApiResponse = await GetAgreements(customerId, "N");

    if (
      validateApiResponse(agreementResponse) &&
      agreementResponse.data.agreement
    ) {
      const activeAgreements = agreementResponse.data.agreement;
      const agreementsInCurrentStore: any[] = activeAgreements.filter(
        (e: any) => e?.storeNumber == LOGGED_IN_STORE
      );

      if (agreementsInCurrentStore?.length) {
        const tranferInitiatedAgreements: any[] =
          agreementsInCurrentStore.filter(
            (e: any) => e?.isTransferInitiated == 1
          );

        if (tranferInitiatedAgreements.length) {
          agreemntInfo.transferInitiated = true;
        } else {
          const transferApplicableAgreements = agreementsInCurrentStore.every(
            (e: any) =>
              !["Promo", "Rent to Rent - Loaner"].includes(e?.agreementType)
          );

          if (transferApplicableAgreements) {
            agreemntInfo = validateTransferAgreements(
              agreementsInCurrentStore,
              agreemntInfo
            );
          } else {
            agreemntInfo.tranferAgreementInfo = 'Cannot transfer promo and loaner agreements'
          }
        }
      } else {
        agreemntInfo.tranferAgreementInfo = "No active agreements";
      }
    }
  } catch (error: any) {
    console.log("Error in getAgreementDetails: ", error?.message);
  } finally {
    return agreemntInfo;
  }
};

const getClubDetails = async (customerId: string): Promise<ClubInformation> => {
  const clubInfomation: ClubInformation = { ...CLUB_INFO_TEMPLATE };
  try {
    const clubDetailsResponse = await GetCustomerCoverage(
      customerId,
      LOGGED_IN_STORE
    );

    if (
      validateApiResponse(clubDetailsResponse) &&
      clubDetailsResponse?.data?.clubInfo
    ) {
      const clubDetails: any = clubDetailsResponse.data.clubInfo;
      clubInfomation.membershipNumber =
        clubDetails.membershipNumber || EMPTY_STRING;
      clubInfomation.clubAmount = clubDetails.clubAmount || EMPTY_STRING;
      clubInfomation.clubName = clubDetails.clubName || EMPTY_STRING;
      clubInfomation.clubStatus = clubDetails.clubStatus || EMPTY_STRING;
      clubInfomation.paymentSchedule =
        clubDetails.paymentSchedule || EMPTY_STRING;
      clubInfomation.startDate = clubDetails.startDate || EMPTY_STRING;
      clubInfomation.nextDueDate = clubDetails.nextDueDate || EMPTY_STRING;
      clubInfomation.clubActivatedStore =
        clubDetails.clubActivatedStore || EMPTY_STRING;
      clubInfomation.originStoreNumber =
        clubDetails.originStoreNumber || EMPTY_STRING;
    }
  } catch (error: any) {
    console.log("Error in getClubDetails: ", error?.message);
  } finally {
    return clubInfomation;
  }
};

const getAlertsList = async (
  customerId: string
): Promise<AlertInformation[]> => {
  let alertsList: AlertInformation[] = [];
  try {
    const alertListResponse: ApiResponse = await GetCustomerAlerts(customerId);

    if (
      validateApiResponse(alertListResponse) &&
      alertListResponse.data.alert
    ) {
      alertsList = alertListResponse.data.alert;
      alertsList = sortByLabel(alertsList, "alertTypeDescEn");
      const otherIndex = alertsList.findIndex(obj => obj.alertTypeDescEn === 'Other');
      if (otherIndex !== -1) {
        const otherObject = alertsList.splice(otherIndex, 1)[0];
        alertsList.push(otherObject);
      }
    }
  } catch (error: any) {
    console.log("Error in getAlertsList: ", error?.message);
  } finally {
    return alertsList;
  }
};

const getCustomerAlerts = async (customerId: string) => {
  let assignedAlerts: any[] = [];
  try {
    const customerAlertResponse = await GetCustomerAlertsById(customerId);
    if (
      validateApiResponse(customerAlertResponse) &&
      customerAlertResponse.data.customerAlertByIdDTO?.value?.customeralert
        ?.length
    ) {
      assignedAlerts =
        customerAlertResponse.data.customerAlertByIdDTO.value.customeralert;
    }
  } catch (error: any) {
    console.log("Error in getCustomerAlerts: ", error?.message);
  } finally {
    return assignedAlerts;
  }
};

const getCustomerInactivePhone = async (
  customerId: string
): Promise<ContactInformations[]> => {
  try {
    const inactivePhones: ContactInformations[] = [];

    const inactivePhoneResponse: ApiResponse = await GetCustomerPhone(
      customerId,
      STRING_NO
    );

    if (
      validateApiResponse(inactivePhoneResponse) &&
      inactivePhoneResponse.data.GetCustomerPhone?.value?.phones?.length
    ) {
      const inactivePhoneList =
        inactivePhoneResponse.data.GetCustomerPhone?.value?.phones;

      inactivePhoneList.forEach((inactivePhoneInfo: any) => {
        if (
          inactivePhoneInfo.active === STRING_ZERO &&
          (inactivePhoneInfo.primaryFlag === STRING_ZERO ||
            inactivePhoneInfo.primaryFlag === "1")
        ) {
          const inactivePhone: ContactInformations = {
            ...CONTACT_INFORMATION_TEMPLATE,
          };
          inactivePhone.phoneNumber =
            inactivePhoneInfo.phoneNumber || EMPTY_STRING;
          inactivePhone.extension =
            inactivePhoneInfo.phoneExtension || EMPTY_STRING;
          inactivePhone.phoneType = inactivePhoneInfo.phoneType || EMPTY_STRING;
          inactivePhone.phoneTypeDesc =
            inactivePhoneInfo.phoneTypeDesc || EMPTY_STRING;
          inactivePhone.instructions = inactivePhoneInfo.note || EMPTY_STRING;
          inactivePhone.callTimeType =
            inactivePhoneInfo.callType || EMPTY_STRING;
          inactivePhone.callTimeTypeDesc =
            inactivePhoneInfo.callTypeDesc || EMPTY_STRING;
          inactivePhone.active =
            inactivePhoneInfo.active === STRING_ZERO ? STRING_NO : STRING_YES;
          inactivePhone.primary =
            inactivePhoneInfo.primaryFlag === STRING_ZERO
              ? STRING_NO
              : STRING_YES;
          inactivePhone.phoneId = inactivePhoneInfo.phoneId || EMPTY_STRING;

          inactivePhones.push(inactivePhone);
        }
      });
    }

    return inactivePhones;
  } catch (error: any) {
    console.log("Error in getInactivePhoneNumbers: ", error?.message);
    return [];
  }
};

const getDecryptedData = (key: string, iv: string, data: string) => {
  try {
    const decipher = createDecipheriv("aes-256-cbc", key, iv);
    let decrptedValue: any = decipher.update(data, "hex", "utf8");
    decrptedValue += decipher.final("utf8");
    return decrptedValue;
  } catch (error: any) {
    console.log("Error in getDecryptedData: ", error?.messsage);
  }
};

/**This one removed due to already handled this flow in onload itself */
// const getDecryptedValues = async (encryptedValues: {
//   dateOfBirthEnc: string;
//   taxIdEnc: string;
//   governmentIdEnc: string;
// }) => {
//   const decryptedValues = {
//     dateOfBirth: EMPTY_STRING,
//     ssn1: EMPTY_STRING,
//     ssn2: EMPTY_STRING,
//     ssn3: EMPTY_STRING,
//     governmentId: EMPTY_STRING,
//   };

//   const payloadForDecrypt: any = {
//     decryptData: {},
//     encryptKey: crypto.randomBytes(16).toString("hex"),
//     ivKey: crypto.randomBytes(8).toString("hex"),
//   };

//   if (encryptedValues.dateOfBirthEnc) {
//     payloadForDecrypt.decryptData.dateOfBirth = encryptedValues.dateOfBirthEnc;
//   }
//   if (encryptedValues.taxIdEnc) {
//     payloadForDecrypt.decryptData.taxId = encryptedValues.taxIdEnc;
//   }
//   if (encryptedValues.governmentIdEnc) {
//     payloadForDecrypt.decryptData.governmentId =
//       encryptedValues.governmentIdEnc;
//   }
//   const decryptResponse: any = await DrawerAudit(payloadForDecrypt);

//   if (
//     validateApiResponse(decryptResponse) &&
//     decryptResponse.data.decryptData
//   ) {
//     if (encryptedValues.dateOfBirthEnc) {
//       decryptedValues.dateOfBirth = getDecryptedData(
//         payloadForDecrypt.encryptKey,
//         payloadForDecrypt.ivKey,
//         decryptResponse.data.decryptData?.dateOfBirth
//       );
//     }

//     if (encryptedValues.taxIdEnc) {
//       const taxIdRes = getDecryptedData(
//         payloadForDecrypt.encryptKey,
//         payloadForDecrypt.ivKey,
//         decryptResponse.data?.decryptData?.taxId
//       );
//       decryptedValues.ssn1 = taxIdRes.slice(0, 3);
//       decryptedValues.ssn2 = taxIdRes.slice(3, 5);
//       decryptedValues.ssn3 = taxIdRes.slice(5);
//     }

//     if (encryptedValues.governmentIdEnc) {
//       decryptedValues.governmentId = getDecryptedData(
//         payloadForDecrypt.encryptKey,
//         payloadForDecrypt.ivKey,
//         decryptResponse.data?.decryptData?.governmentId
//       );
//     }
//   }

//   return decryptedValues;
// };

const getAddressValidation = async (payload: any) => {
  let getAddress: SuggestedAddress = {
    storeConfig: {},
    validateAddress: {},
  };

  try {
    const response: any = await ValidateAddress(payload);
    if (validateApiResponse(response) && response.data) {
      getAddress = response.data;
    }
    return getAddress;
  } catch (e: any) {
    console.log("Error in getAddressValidation ", e?.message);
    return getAddress;
  }
};

const getCustomerAddresses = async (customerId: string) => {
  try {
    let activeAddress: AddressDetails[] = [];
    let inactiveAddress: AddressDetails[] = [];
    const [activeAddressResponse, inactiveAddressResponse] = await Promise.all([
      GetCustomerAddress(customerId, STRING_YES),
      GetCustomerAddress(customerId, STRING_NO),
    ]);
    if (
      validateApiResponse(activeAddressResponse) &&
      activeAddressResponse.data?.GetCustomerAddress?.value?.addresses?.length
    ) {
      activeAddress = buildAddressInfo(
        activeAddressResponse.data.GetCustomerAddress.value.addresses
      );
    }

    if (
      validateApiResponse(inactiveAddressResponse) &&
      inactiveAddressResponse.data?.GetCustomerAddress?.value?.addresses?.length
    ) {
      inactiveAddress = buildAddressInfo(
        inactiveAddressResponse.data.GetCustomerAddress.value.addresses
      );
    }

    const getAddressResponse: AddressInfo = buildAdressInformations({
      activeAddresses: activeAddress,
      inActiveAddresses: inactiveAddress,
    });

    let primaryAddressArray = getAddressResponse.primaryAddress || [];
    primaryAddressArray = [
      ...new Map(
        primaryAddressArray.map((item) => [item.addressId, item])
      ).values(),
    ];

    const activeCustomerAddress: AddressDetailsBinder[] =
      buildAddressDetailsBinder(getAddressResponse);
    const inActiveCustomerAddress: AddressDetails[] =
      buildInActiveAddressDetails(inactiveAddress);
    return {
      activeAddresses: activeCustomerAddress,
      inActiveAddresses: inActiveCustomerAddress,
      primaryAddressArray: primaryAddressArray,
    };
  } catch (error: any) {
    console.log("Error in getCustomerAddresses: ", error?.message);
    return {
      activeAddresses: [],
      inActiveAddresses: [],
      primaryAddressArray: [],
    };
  }
};

const updateAddressInformation = async (payload: any) => {
  try {
    const response: any = await UpdateAddressInfo(payload);
    return response?.data;
  } catch (e: any) {
    console.log("Error in getAddressValidation ", e?.message);
    return [];
  }
};

const getIntellicheckDetails = async (gcid: any, setterFunction: any) => {
  const intelllicheckDetailsResp: any = await getIntellicheckStatus(gcid, 'rac', true);
  console.log('IntellcheckDetails Response', intelllicheckDetailsResp);
  if(intelllicheckDetailsResp?.status == 200) {
    setterFunction(intelllicheckDetailsResp?.data)
  } else if(intelllicheckDetailsResp?.status == 500) {
    setterFunction('');
  }
}

export {
  getCoworkerRole,
  getCustomerLegalHoldStatus,
  getCustomerInformation,
  linkCustomerInfoInWeb,
  getStoreConfigurations,
  getFaxSheetDetails,
  getDropdownData,
  getAgreementDetails,
  getCustomerAlerts,
  getAlertsList,
  getClubDetails,
  getCustomerInactivePhone,
  getDecryptedData,
  getFeatureFlagInfo,
  getAgreementHistory,
  getAddressValidation,
  getCustomerAddresses,
  updateAddressInformation
};



export const getVerificationStatus = async (response: any) => {
  // Define levels based on the question
  const level1 = ['ID', 'RESIDENCE'];
  const level2 = ['ID', 'RESIDENCE', 'EMPLOYMENT'];
  const level3 = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];
  const level4 = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];

  // Check if verificationDocuments array has length
  if (!Array.isArray(response.verificationDocuments) || response.verificationDocuments.length === 0) {
    return false;
  }

  // Get the verification level from the response
  const verifyLevel = response.verifyLevel;

  // Based on the verification level, filter the documents
  let levelDocuments: any = [];
  switch (verifyLevel) {
    case '1':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level1.includes(doc.verifyType)
      );
      break;
    case '2':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level2.includes(doc.verifyType)
      );
      break;
    case '3':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level3.includes(doc.verifyType)
      );
      break;
    case '-1':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level4.includes(doc.verifyType)
      );
      break;
    default:
      return false;
  }

  console.log("levelDocuments", levelDocuments);

  // Check if all documents have 'verified' status as 'Y'
  const allVerified = levelDocuments.every((doc: any) => doc.verificationStatus === 'VERIFIED');
  console.log("allVerified", allVerified);


  // Return true if all documents are verified, else return false
  return allVerified;
}

const pendingStoreActionItemClear = async (getApprovalDetails: any, history: any, customerId?: any) => {

  if (getApprovalDetails) {
    console.log("getApprovalDetailsTest", getApprovalDetails)
    const getVerificationStatusRes: any = await getVerificationStatus(getApprovalDetails)
    console.log("getVerificationStatusRes", getVerificationStatusRes)

    const storeActionItemId = window.sessionStorage.getItem('storeActionItemId')
    const navigatedFrom = window.sessionStorage.getItem('navigatedFrom')
    const navCustomreId = window.sessionStorage.getItem('navCustomreId')

    console.log("navigatedFrom", navigatedFrom)
    console.log("navigatedCustomerId", customerId)


    if (getVerificationStatusRes && navigatedFrom && (navigatedFrom.includes(customerId))) {
      console.log("navigatedBefLog", history)
      history.push(`/${navigatedFrom}`);
      console.log("navigatedAftLog", history)

      window.sessionStorage.removeItem('navigatedFrom')
      // Use history.replace to update the state
      history.replace({
        pathname: history?.location?.pathname,
        state: ''
      });
    }

    if (getVerificationStatusRes && storeActionItemId && (navCustomreId == customerId)) {

      const payload: any =
      {
        storeNumber: window.sessionStorage.getItem('storeNumber'),
        storeActionItemIds: [
          storeActionItemId
        ]
      }
      const manageStoreActionItemRes: any = await manageStoreActionItem(payload)
      debugger
      if (manageStoreActionItemRes && manageStoreActionItemRes?.status == 200) {
        window.sessionStorage.removeItem('storeActionItemId')
        window.sessionStorage.removeItem('navCustomreId')
        console.log("navigatedAftStoreActionItem", history)

        // Use history.replace to update the state
        history.replace({
          pathname: history?.location?.pathname,
          state: ''
        });
      }
    }
  }

}

export default pendingStoreActionItemClear;

